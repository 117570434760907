import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/DiscoverLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <p><strong parentName="p">{`Usable with CoreMedia Content Cloud - Service`}</strong></p>
    <hr></hr>
    <p>{`CoreMedia Campaigns is a service that lets you define campaigns in a UI based on REACT. You can access your campaign
data through a GraphQL endpoint. By default, the campaigns are coupled with CoreMedia Studio and the CoreMedia system.`}</p>
    <p><img parentName="p" {...{
        "src": "../../../../images/services/campaigns/campaigns_delivery_architecture_simplified.png",
        "alt": "Overview of the campaign architecture"
      }}></img></p>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`Find an introduction into CoreMedia Campaigns at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/services/campaigns/introduction-to-campaigns/"
        }}>{`Introduction`}</a>{`.`}</li>
      <li parentName="ul">{`Find a user guide for the Campaign App at `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/services/campaigns/user-guide/"
        }}>{`User Guides`}</a>{`.`}</li>
      <li parentName="ul">{`Learn how to connect the Campaign Service with your sites at the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/services/campaigns/developer-guide/"
        }}>{`Developer Guides`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      